<template>
  <v-container fluid class="grey lighten-3">

    <v-card class="mb-5">

      <v-tabs v-model="tab" @change="tabChanged">
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <AggregateFeeds v-if="tab == 0"></AggregateFeeds>
        </v-tab-item>
        <v-tab-item>
          <InitializeCampaigns v-if="tab == 1"></InitializeCampaigns>
        </v-tab-item>
        <v-tab-item>
          <MutationLog v-if="tab == 2"></MutationLog>
        </v-tab-item>
      </v-tabs-items>

    </v-card>

  </v-container>
</template>

<script>
export default {
  name: 'AdCustomizer',

  data () {
    return {
      tab: null,
      items: ['Aggregate feeds', 'Initialize campaigns', 'Mutation log'],
      lsTab: 'adCustomizer_tab',
    }
  },

  components: {
    AggregateFeeds: () => import('@/components/adCustomizer/AggregateFeeds.vue'),
    InitializeCampaigns: () => import('@/components/adCustomizer/InitializeCampaigns.vue'),
    MutationLog: () => import('@/components/adCustomizer/MutationLog.vue'),
  },

  methods: {
    tabChanged () {
      localStorage.setItem(this.lsTab, JSON.stringify(this.tab))
    },
  },

  created: function () {
    if (localStorage.getItem(this.lsTab)) {
      this.tab = JSON.parse(localStorage.getItem(this.lsTab))
    }
  },
}
</script>

<style scoped>
</style>